<template>
  <header v-if="!hasMini" id="header">
    <div class="inner-header">
      <div class="left">
        <!-- <router-link class="brand" @click.native="showQuickNav()" to="/">
          <base-svg class="logo" svg="logo" height="1.3rem"/>
          <base-svg class="mark" svg="mark" height="1.75rem"/>
        </router-link> -->

        <div class="brand" @click="isExpanded();possibleTop($event);" data-to="/">
          <transition :name="titleAnimate">
            <div v-if="titleShow">
              <svg class="nourish-logo" width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8155 0.333496H25.2108L26 27.543L22.3455 28.528C19.0387 21.6494 15.9202 17.0187 12.0139 12.5849C14.2398 17.1322 16.1344 21.5113 17.9382 26.9294L14.7827 29.0821C12.9082 27.1982 11.3929 25.6079 10.0191 24.1662C9.32427 23.437 8.66566 22.7459 8.01512 22.0739C8.35032 23.228 8.64076 24.4982 8.89176 25.9526L9.11995 27.2747L6.17839 29.3335L1.08112 27.7603L2.16583 23.8458L4.67471 24.6202C3.75914 20.7111 2.39269 18.4625 0 14.55L2.80964 11.8344C6.16062 14.5846 8.47279 16.8392 10.8231 19.2666C8.89802 14.89 6.71962 10.7221 4.00901 5.5359C3.5973 4.74818 3.17331 3.93697 2.73606 3.09797L5.71753 0.595527C12.5625 7.05909 17.298 11.8688 21.7241 19.129L20.8155 0.333496Z" fill="black"/>
              </svg>
            </div>
          </transition>
        </div>

        <div class="primary">
          <router-link @click.native="isExpanded();possibleTop($event);" v-for="link in links.core" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}" exact>{{ link.name }}</router-link>
        </div>
        <div class="secondary">
          <router-link @click.native="isExpanded()" v-for="link in links.primary" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}">{{ link.name }}</router-link>
        </div>
      </div>

      <div class="right">
        <div class="links">
          <router-link @click.native="toggleMobileMenu()" v-for="link in links.secondary" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}">{{ link.name }}</router-link>
          
          <!-- THREADS -- THREADS -->
          <!-- <router-link :to="'/~' + getAccount.screen_name" v-if="isSignedIn && hasThreads">My Threads</router-link>
          <div class="pro-container" v-if="isSignedIn && hasThreads">
            <button class="threads no-arrow" @click="goThreadShare()">
              <span class="flex flex-center">
                <span class="icon">
                  <global-svg type="blue-heart" color="white" width="11px" height="11px"></global-svg>
                </span>
              </span>
            </button>
          </div> -->
          
          <div v-if="!isSignedIn">
            <button @click="triggerSignUp()">Sign Up</button>
            <button class="secondary" @click="triggerSignIn()">Sign In</button>
          </div>
          <router-link v-else @click.native="toggleMobileMenu()" to="/account" :class="{'active' : $route.path == '/account'}">Account</router-link>
          
          <div class="mode" v-if="isSignedIn">
            <Mode/>
          </div>

          <div class="pro-container" v-if="isSignedIn && !isPro">
            <button class="go-pro no-arrow" @click="triggerGoPro()">Go Pro</button>
          </div>

          <div class="app-container">
            <button @click="getApp()">Get the app</button>
          </div>
        </div>
        <div class="mobile-menu-action">
          <Mode/>
          <button v-if="isSignedIn && !isPro" class="go-pro no-arrow" @click="triggerGoPro()">Go Pro</button>
          <button v-else-if="!isSignedIn" class="sign-up-btn" @click="triggerSignUp()">Sign Up</button>
          <!-- <div class="menu" @click="toggleMobileMenu()" v-if="!isAlpha">
            <base-svg v-if="!showMobile" svg="menu" height="0.7rem"/>
            <base-svg v-else svg="close" height="0.85rem"/>
          </div> -->
          <button v-if="!isApp" @click="getApp()">Get the app</button>
        </div>
      </div>
    </div>
    
    <transition name="slide">
      <div v-show="showMobile" class="mobile-menu">
        <div class="mobile-content">
          <router-link @click.native="toggleMobileMenu()" v-for="link in links.core" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}">{{ link.name }}</router-link>
          <router-link @click.native="toggleMobileMenu()" v-for="link in links.primary" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}">{{ link.name }}</router-link>
          <router-link @click.native="toggleMobileMenu()" v-for="link in links.secondary" :key="link.route" :to="link.route" :class="{'active' : $route.path == link.route}">{{ link.name }}</router-link>
          <a v-if="!isSignedIn" @click="triggerSignIn()">Sign In</a>
          <router-link v-else @click.native="toggleMobileMenu()" to="/account" :class="{'active' : $route.path == '/account'}">Account</router-link>
          
          <div class="mobile-mode">
            <Mode/>
          </div>
        </div>

        <div v-if="!isSignedIn" class="mobile-cta">
          <El state="sign-up"/>
        </div>

        <div v-if="isSignedIn && !isPro" class="mobile-cta">
          <El state="go-pro"/>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
  // import API from "app/axios.js";
  import El from '@/components/_v5/cta/El.vue';
  import Mode from '@/components/_v5/base/Mode.vue';
  import API from 'app/axios';

  export default {
    data() {
      return {
        title: 'Nourish',
        titleShow: true,
        titleAnimate: 'fade-forward',
        showMobile: false,
        links: {
          core: [
            { name: 'Search', route: '/search' },
            { name: 'Dashboard', route: '/dashboard' },
            { name: 'News', route: '/' },
            // { name: 'Mine', route: '/mine' },
            // { name: 'Summary', route: '/' },
            // { name: 'News', route: '/news' },
            // { name: 'Topics', route: '/topics' },
          ],
          primary: [
            // { name: 'Threads', route: '/threads' },
            // { name: 'Puzzles', route: '/puzzles/emoji' },
            { name: 'Mission', route: '/mission' },
            // { name: 'Search', route: '/search' },
            // { name: 'Open', route: '/open' },
          ],
        },
        debuouncedRoutePath: this.$route.path,
      }
    },

    components: {
      El,
      Mode,
    },

    mounted () {
      // Push correct edition route
      // API.getEditions().then(() => {
      //   this.links.core.splice(1, 0, { name: `${this.getEdition}`, route: '/news' });
      // })

      // Handle menu toggle events
      this.EventBus.$on('header:hide', this.isExpanded);
      this.EventBus.$on('header:title', (payload) => {
        // console.log(`TITLE CHANGE! `, payload);
        this.updateTitle(payload)
      });

    },

    computed: {
      isAlpha() {
        return this.$store.getters.isAlpha;
      },

      hasThreads() {
        return this.$store.getters.hasThreads;
      },

      hasMini() {
        return this.$store.getters.hasMini;
      },

      getAccount() {
        return this.$store.getters.getAccount;
      },

      getEdition() {
        let country = this.$store.getters.getEditions;
        switch (country) {
          case 'uk': return 'UK';
          case 'us': return 'USA';
          case 'au': return 'Australia';
          case 'nz': return 'New Zealand';
          default: return 'All News';
        }
      },

      isSignedIn() {
        return this.$store.getters.isLoggedIn; 
      },

      isPro() {
        return this.$store.getters.isPro;
      },

      liveRoutePath() {
        return this.$route.path;
      },

      deviceType() {
        if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return 'android'
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          return 'ios'
        } else {
          return 'desktop'
        }
      },

      isApp() {
        return this.$store.getters.hasApp;
      },
    },

    methods: {
      getApp() {
        if (this.deviceType == 'ios') {
          window.open('https://nourished.news/ios', '_blank').focus();
        } else if (this.deviceType == 'android') {
          window.open('https://nourished.news/android', '_blank').focus();
        } else {
          // Fire QR Modal
          this.EventBus.$emit('modal:qr');
        }
      },

      updateTitle(payload) {
        let sDefault = 'Nourish';

        if (this.title == payload.title) {
          // ignore!
          // console.log(`Title (${payload.title} already set: ${this.title}`);
          return;
        }

        if (!payload.title && (this.title == sDefault)) {
          // ignore! -- *already* set to the default..
          // console.log(`Title (${payload.title} already default: ${sDefault})`);
          return;
        }

        // default: Nourish!
        if (!payload.title) {
          payload.title = sDefault;
        }

        this.titleAnimate = `fade-${payload.direction}`;

        setTimeout(() => {
          this.titleShow = false;
          this.title = payload.title;
        }, 1);
        
        setTimeout(() => {
          this.titleShow = true;
        }, 100);
      },

      possibleTop(e) {
        let sTo = e.target?.closest('[data-to]')?.dataset?.to;
        let sPath = e.target?.pathname || sTo || '/';
        console.log(`\x1b[32m[Nav:Click] `, sPath, this.debuouncedRoutePath);
        if (sPath == this.debuouncedRoutePath) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          // ------
          if (sPath == '/') {
            // console.log(`\x1b[32m[Nav:Click] RELOAD!`);
            // tell the user we're reloading!
            this.EventBus.$emit("device:haptic", 'success');

            API.getHomeV6({ cluster: 'cat', force: true, });

            // reload the pro edition (if they're a pro?)
            if (this.isPro) {
              API.getHomePro({ cluster: 'cat', force: true, });
            }
          }
        } else if (sTo) {
          // console.log(`\x1b[32m[Nav:Click] Going to... `, sPath);
          this.$router.push(sTo);
        }
        console.log(`\x1b[32m[Nav:Click] Nothing to do?`, e.target);
      },

      goThreadShare() {
        this.EventBus.$emit('thread:share', {
          on: true,
        });
      },

      toggleMobileMenu() {
        this.showMobile = !this.showMobile;
      },

      isExpanded() {
        if (this.showMobile) {
          this.toggleMobileMenu();
        }
      },

      showQuickNav() {
        if (this.isSignedIn && this.isAlpha) {
          this.EventBus.$emit('nav:toggle');
        }
      },

      triggerSignUp() {
        this.EventBus.$emit('modal:auth', 'sign-up');
      },

      triggerSignIn() {
        this.EventBus.$emit('modal:auth', 'sign-in');
      },

      triggerGoPro() {
        this.EventBus.$emit("didSelectSubscribe", {
          didSelect: 'true' 
        });
      }
    },
    watch: {
      liveRoutePath(to) {
        window.setTimeout(() => {
          this.debuouncedRoutePath = to;
        }, 100);

        // reset the title on *every* route..
        this.EventBus.$emit('header:title', {
          title: false,
          direction: 'forward',
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .fade-forward-enter-active,
  .fade-forward-leave-active,
  .fade-back-enter-active,
  .fade-back-leave-active {
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .fade-forward-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }

  .fade-forward-enter {
    opacity: 0;
    transform: translateY(10px);
  }

  .fade-back-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .fade-back-enter {
    opacity: 0;
    transform: translateY(-10px);
  }


  header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: env(safe-area-inset-top) 1rem 0 1rem;
    height: calc(env(safe-area-inset-top) + $header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: 1px solid $neutral-500;
    color: $neutral-100;
    z-index: 9999999;

    .darkmode & {
      background: $neutral-200;
      border-bottom: 1px solid $neutral-300;
      color: $neutral-600;
    }

    .inner-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1400px;
      margin: auto;
      width: 100%;
    }

    button {
      padding: 0.7rem 1.2rem;
      margin-left: 0.5rem;
    }

    .breaker {
      margin: 0 0.3rem;
      color: $neutral-400;
    }
    
    .profile {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      cursor: pointer;

      .profile-image {
        display: flex;
        align-items: center;
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 0.5rem;
      }
    }

    .left a:not(.brand),
    .right a:not(.brand) {
      font-weight: bold;
      margin-left: 0.8rem;
      padding: 1.2rem 0;
      font-size: 0.9rem;
      border-top: 3px solid transparent;
      @include transition;

      &.router-link-active {
        border-top: 3px solid $yellow-tone-3;
      }

      &:not(.active):hover {
        border-top: 3px solid $neutral-600;

        &.darkmode & {
          border-top: 3px solid $neutral-100;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;

      .brand {
        width: max-content;
        position: relative;
        font-size: 1.3rem;
        font-family: 'Brawler';
        font-weight: bold;
        color: $neutral-100;
        cursor: pointer;

        .darkmode & {
          color: $neutral-600;
        }
      }

      .primary {
        display: none;
      }
      
      .secondary {
        display: none;
      }
    }

    .mid {
      display: flex;
      align-items: center;
      font-weight: bold;

      @media (max-width: 780px) {
        display: none;
      }

      a {
        font-weight: bold;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      .primary {
        display: none;
      }
      
      .secondary {
        display: none;
      }
    }

    .right {
      .links {
        display: none;
      }

      .pro-container {
        margin-left: 0.5rem;
        align-self: center;

        .threads {
          background: $blue-tone-4;
        }
      }

      .mobile-menu-action {
        display: flex;

        .go-pro,
        .sign-up-btn {
          // margin-right: 0.5rem;
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2rem;
        cursor: pointer;
      }
    }

    .mobile-menu {
      position: fixed;
      top: calc(env(safe-area-inset-top) + $header-height);
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: rgba($neutral-600, 0.98);
      color: $neutral-100;
      overflow-y: scroll;
      height: 100%;
      padding-bottom: calc(env(safe-area-inset-bottom) + 4rem);

      .darkmode & {
        background: rgba($neutral-100, 0.98);
        color: $neutral-600;
      }

      .mobile-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2rem 0;
        height: 100%;

        a {
          font-size: 0.9rem;
          font-weight: bold;
          margin: 1rem 0;
          padding-left: 1.5rem;
          border-left: 5px solid transparent;

          &.active {
            border-left: 5px solid $yellow-tone-3;
          }

          animation-name: slideUp;
          animation-duration: 0.75s;
          animation-timing-function: ease-in;
        }

        .mobile-mode {
          margin: 1rem 0 1rem 5px;
          padding-left: 1.5rem;
          animation-name: slideUp;
          animation-duration: 0.75s;
          animation-timing-function: ease-in;
        }
      }

      .mobile-cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1.5rem calc(env(safe-area-inset-bottom) + 2rem) 1.5rem;
        width: 100%;
        z-index: 10;
        // text-align: center;
        // background: $neutral-500;

        // .darkmode & {
        //   background: $neutral-200;
        // }

        // h1 {
        //   font-size: 1.2rem;
        //   margin-bottom: 0.5rem;
        // }

        // p {
        //   font-size: 0.9rem;
        //   line-height: 1.3;
        //   margin-bottom: 2rem;
        // }
      }
    }

    .brand {
      width: 1.75rem;
      padding-top: -2px;
    }

    .logo {
      display: none;
    }

    .mark {
      display: inline-block;
      margin-top: 9px;
    }
  }

  @media (min-width: 780px) {
    header {
      .left {
        .primary {
          display: flex;
          margin-left: 1rem;
        }

        .secondary {
          display: flex;
        }
      }
      
      .right {
        .links {
          display: flex;
        }

        .menu {
          display: none;
        }

        .mode {
          display: flex;
          align-items: center;
          margin-left: 1rem;
        }

        .mobile-menu-action {
          display: none;
        }
      }

      .mobile-menu {
        display: none;
      }

      .brand {
        width: unset;
      }

      .logo {
        display: inline-block;
      }

      .mark {
        display: none;
      }
    }
  }

  .app-container {
    display: flex;
    align-items: center;
  }
</style>