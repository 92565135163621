<template>
  <transition name="slide-up">
    <div v-if="visible" class="coaching-wrapper" :class="state" :style="{'--caretPosition': caretPosition}">      
      <div v-if="state === 'personalise'" class="content-state">
        <div class="intro">
          <h2>Get more from Nourish</h2>
          <p>Answer a few quick questions and we'll help get started with your reading goals.</p>
        </div>

        <div class="personalise-questions">
          <div class="personalise-q-group" v-for="(track, idx) in tracks" :key="idx">
            <div v-if="activeTrack === track.state">
              <h3>{{ track.question }}</h3>
              <div class="choices">
                <button v-for="(choice, idxx) in track.choices" :key="idxx" @click="setTrack(track, choice)">{{ choice }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="state === 'playlist'">
        <PlaylistCoach :item="data" />
      </div>

      <div v-else-if="state === 'skip'" class="content-skip">
        <h2>Why are you skipping</h2>

        <div class="actions">
          <button @click="setSkipReason()">Not interested</button>
          <button @click="setSkipReason()">I've read enough</button>
          <button @click="setSkipReason()">I know this already</button>
        </div>
      </div>

      <div v-else-if="state === 'cta'" class="content-cta">
        <h2>{{ content.heading }}</h2>
        <button @click="action()">{{ content.cta.text }}</button>
      </div>

      <div v-else-if="state === 'pro'" class="content-cta">
        <h2>{{ content.heading }}</h2>
        <button @click="action()">{{ content.cta.text }}</button>
      </div>

      <div v-else>
        <dial v-if="content.dial === 'full'" size="coaching" />
        <img v-if="content.image" :src="require(`@assets/images/coaching/${content.image}.png`)" class="content-image" />
        <div class="content-group">
          <dial v-if="content.dial === 'content'" size="tab" />
          <div class="content-stack" :class="{'align-left' : content.dial === 'content'}">
            <h2 v-if="content.heading" v-html="content.heading"></h2>
            <p v-if="content.text" v-html="content.text"></p>
          </div>
        </div>
        <button @click="action()" v-if="content.cta && content.cta.text">{{ content.cta.text }}</button>
      </div>

      <div class="ghost-container" v-if="content.ghost && state !== 'skip'">
        <button class="btn ghost" @click="hideModal()">{{ content.ghost }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
  import PlaylistCoach from '@/components/_v5/PlaylistCoach.vue';

  export default {
    data() {
      return {
        visible: false,
        tab: 'dashboard',
        caretPosition: '0',

        state: null,

        tracks: {
          "track-1": {
            state: 1,
            question: 'How do you feel about the news?',
            choices: ['Negative', 'Neutral', 'Positive']
          },
          "track-2": {
            state: 2,
            question: 'How much do you want to read?',
            choices: ['5 mins', '10 mins', '15+ mins']
          },
          "track-3": {
            state: 3,
            question: 'When do you read?',
            choices: ['Morning', 'Afternoon', 'Evening']
          }
        },
        activeTrack: 1,
        trackResponses: {},
        data: {},
        content: {
          dial: false,
          heading: null,
          text: null,
          image: null,
          cta: {
            text: null,
            action: {
              type: 'close',
            }
          },
          ghost: `Maybe later`,
        }
      }
    },

    components: {
      PlaylistCoach,
    },

    mounted () {
      this.EventBus.$on('coaching:hide',() => {
        this.visible = false;
      });

      this.EventBus.$on('coaching:show',(payload) => {
        this.tab = payload.tab;
        
        if (payload.state) {
          this.state = payload.state;
        }

        if (payload.data) {
          this.data = payload.data;
        }

        if (payload.content) {
          this.content = {...this.content, ...payload.content};
        }
        
        this.setCaretPosition();
        this.visible = true;
      });

      // @@ JASON -- NB: The use of `` (backticks) not '' (inverted commas) please for anything that's ever going to have text (cos 'that's') ... see how that breaks? ... but `that's` is valid :) 

      // this.EventBus.$emit('coaching:show', {
      //   tab: `dashboard`,
      //   content: {
      //     dial: `full`, //or// `content`, // - Default is false, to show large dial above, set to `full`. To show dial smaller and left of content, set to `content`
      //     heading: `Heading text`, // - Don`t include to hide heading.
      //     text: `Paragraph text`, // - Don`t include to hide paragraph.
      //     cta: {
      //       text: `Got it!`, // - Button text. Don`t include to hide button.
      //       action: {
      //         type: `close`, // - No action needed if it`s just to close, will close by default, otherwise pass `route` to navigate to another route and close coaching.
      //         route: `` // - Set type to `route` and pass the route you want it to go.
      //         // Action designed like this to have some flexibiltiy later with different types of actions
      //       }
      //     }
      //   }
      // });
    },

    methods: {
      setCaretPosition() {
        switch(this.tab) {
          case 'home': this.caretPosition = '-76%'; return;
          case 'mine': this.caretPosition = '-38%'; return;
          case 'search': this.caretPosition = '38%'; return;
          case 'account': this.caretPosition = '77%'; return;
          default: this.caretPosition = '0';
        }
      },

      setTrack(track, choice) {
        this.visible = false;
        this.trackResponses[`track-${track.state}`] = choice;

        if (this.activeTrack === Object.keys(this.tracks).length) {
          this.state = null;
          this.content = {
            heading: `You're all set. Let's get reading`,
            text: `We've gone ahead and set up some initial reading goals for you. These help give you the best coverage for the amount you want to read.`,
            cta: {
              text: 'Start reading',
              action: {
                type: 'close',
              }
            }
          }

          // @Jim - At this point, all questions have been answered. We can send to API etc or just end here
        } else {
          this.activeTrack = track.state + 1;
        }

        setTimeout(() => {
          this.visible = true;
        }, 100);
      },

      action() {
        switch (this.content?.cta?.action?.type) {
          case 'event':
            this.EventBus.$emit(this.content.cta.action?.event, this.content.cta.action?.payload);
            this.visible = false;
            return;
          case 'route':
            this.$router.push(this.content.cta.action.route);
            this.visible = false;
            return;
          default:
            this.visible = false;
            return;
        }
      },

      hideModal() {
        this.visible = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';
  @import 'src/assets/scss/v5.scss';

  .coaching-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: fixed;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 6.6rem;
    z-index: 9999999999;
    border-radius: 15px;
    padding: 3rem 2rem;
    background: $neutral-600;
    color: $neutral-100;
    box-shadow: 0 0 40px 0 rgba($neutral-100, 0.15);
    max-width: 500px;
    margin: auto;

    p, button {
      margin-top: 1rem;
    }

    h2 {
      font-size: 1.4rem;
      font-family: 'Brawler';
      text-wrap: balance;
    }

    h3 {
      font-size: 1rem;
      font-family: 'Brawler';
      text-wrap: balance;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
    }
    
    button {
      width: 100%;
    }

    .content-state {
      .personalise-questions {
        margin-top: 1.5rem;
        width: 100%;

        .personalise-q-group {
          width: 100%;

          h2 {
            text-align: center;
            margin-bottom: 1rem;
          }

          .choices {
            display: flex;
            justify-content: space-between;
            
            button {
              width: 100%;
              margin: 0 0.25rem;
            }
          }
        }
      }
    }

    .content-skip {
      display: flex;
      flex-direction: column;
      width: 100%;

      h2 {
        font-family: 'Brawler';
        font-size: 24px;
        margin-top: 0;
      }

      .actions {
        display: flex;
        flex-direction: column;
        font-family: 'Circular';
        margin-top: 0.5rem;
        width: 100%;

        button {
          padding: 15px;
          text-align: center;
          outline: none;
          border: none;
          font-size: 16px;
          background: $neutral-500;
          color: $neutral-100;
          margin: 0;
          border-radius: 500px;
          width: 100%;

          .darkmode & {
            background: $neutral-300;
            color: $neutral-600;
          }

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .content-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h2 {
        font-size: 1.2rem;
        font-family: 'Circular', 'Helvetica', 'Arial', 'sans-serif';
        flex: 1;
        width: 100%;
        text-align: left;
      }

      button {
        width: auto;
        flex: unset;
        margin: 0;
      }
    }

    .content-image {
      height: 5rem;
      object-fit: contain;
      margin-bottom: 1rem;
    }

    .content-group {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      
      .content-stack {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &.align-left {
          align-items: flex-start;
          text-align: left;
          padding-bottom: 0.5rem;
          margin-left: 1rem;
          width: 100%;
          flex: 1;

          h2 {
            margin-top: 0;
          }

          p {
            margin-top: 0.5rem;
          }
        }
      }
    }

    .darkmode & {
      background: $neutral-200;
      color: $neutral-600;
      box-shadow: 0 0 40px 0 rgba($yellow-tone-3, 0.08);
    }

    &.cta,
    &.pro {
      padding: 1rem 1rem 1rem 1.5rem;
      background: $yellow-tone-3;
      color: $neutral-100;
      
      .content-cta {
        h2 {
          font-size: 1rem;
          margin-right: 1rem;
        }

        button {
          background: $neutral-600;
          color: $neutral-100;
        }
      }
    }

    &.pro {
      background: $green-tone-3;
      color: $neutral-600;

      .content-cta {
        button {
          background: $neutral-600;
          color: $neutral-100;
        }
      }
    }

    &.playlist {
      padding-top: 0;
    }

    &:after {
      content:'';
      position: absolute;
      left: var(--caretPosition);
      right: 0;
      bottom: -10px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 10px solid $neutral-600;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      .darkmode & {
        border-top: 10px solid $neutral-200;
      }
    }
  }

  .ghost-container {
    // margin-top: 1rem;
    margin-bottom: -1rem;
  }
</style>