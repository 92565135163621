<template>
  <div class="coaching-inner-wrapper">
    <div class="content">
      <h2>{{ content.heading }}</h2>
      <p>{{ content.paragraph }}</p>

      <form v-if="!isWorking">
        <div class="row" v-if="content.state === 'sign_up'">
          <input type="text" v-model="form.first_name" placeholder="First name">
          <input type="text" v-model="form.last_name" placeholder="Last Name">
        </div>

        <input type="email" v-model="form.email" placeholder="Email" autocomplete="email">
        
        <input type="password" v-model="form.password" autocomplete="new-password" :placeholder="content.state === 'sign_up' ? 'Create a password' : 'Password'">

        <div class="checkbox-container" v-if="content.state === 'sign_up'">
          <input v-model="form.terms" type="checkbox" id="terms-checkbox">
          <label for="terms-checkbox">
            <p>I'm onboard with the <a href="https://nourished.news/privacy" target="_blank">privacy</a> and <a href="https://nourished.news/terms" target="_blank">terms</a></p>
          </label>
        </div>
      </form>

      <div v-else class="loading">
        <span class="purple-loader"></span>
      </div>
    </div>

    <div class="action">
      <div class="message" v-if="responseErrorMessage">
        {{ responseErrorMessage }}
      </div>
      <button @click="auth()">{{ content.action }}</button>
    </div>
  </div>
</template>

<script>
  import API from "app/axios.js";

  export default {
    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          terms: false,
        },
        isWorking: false,
        responseErrorMessage: null,
      }
    },
    
    props: {
      code: {
        type: String,
        default: null,
      },
      content: {
        type: Object,
        default: null 
      },
    },

    methods: {
      auth() {
        this.responseErrorMessage = null;
        this.isWorking = true;

        if (this.content.state === 'sign_up') {
          API.post(`/account`, this.form).then((response) => {   
            if (response.status.toString().substring(0,1) == 2) {
              this.$store.commit("updateAccount", response.data);
            }
            
            // Plausible tracking: (User account created)
            this.EventBus.$emit('track:event', {
              label: 'SignUp: Initial',
              once: true, // default
            });

            this.isWorking = false;
            this.$emit('callback', `${this.content.state}`);
          }).catch((error) => {
            this.isWorking = false;
            this.handleResponseError(error);
          });
        }

        if (this.content.state === 'sign_in') {
          API.post(`/account/login`, this.form.sign_in).then((response) => {     
            this.isWorking = false;

            if (response.status.toString().substring(0,1) == 2) {
              this.$store.commit("updateAccount", response.data);

              this.EventBus.$emit("appCallback", {
                type: 'authCallback',
                data: response.data.token
              });

              this.$emit('callback', `${this.content.state}`);
            } else {
              this.responseErrorMessage = `Sorry. Something went wrong. Please try again.`;
            }
          }).catch((error) => {
            this.isWorking = false;

            if (error.response.status === 400) {
              this.responseErrorMessage = `Are those details correct? We can't find that account.`;
            } else {
              this.responseErrorMessage = `Sorry. Something went wrong. Please try again.`;
            }
          });
        }
      },

      handleResponseError(error) {
        switch (error.response.data.error.reason) {
          case 'exists':
            this.responseErrorMessage = `It looks like you're already signed up. Please sign in.`;
            break;
          case 'email':
            this.responseErrorMessage = `That email address doesn't seem valid. Please check it and try again.`;
            break;
          case 'password-length':
            this.responseErrorMessage = `We couldn't accept that password.  Please provide a password at least 10 characters long.`;
            break;
          case 'missing-fields':
            this.responseErrorMessage = 'It looks like some information was missing. Please check and try again.';
            break;
          default:
            this.responseErrorMessage = `Uh oh, we're really sorry but that didn't work. Please try again.`;
            break;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .coaching-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
  }

  h2 {
    font-family: 'Brawler';
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 18px;
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }

  form {
    background: none;
    margin-top: 3rem;

    .row {
      display: flex;
      align-items: center;

      input:first-child {
        margin-right: 1rem;
      }
    }

    input {
      padding: 0.75rem 0;
      background: none;
      border-bottom: 1px solid $purple-tone-4;
      margin-bottom: 1rem;
      font-size: 16px;
      color: $neutral-600;
      outline: none;

      &::placeholder {
        color: $purple-tone-2;
      }

      &:focus {
        box-shadow: none;
        border-bottom: 1px solid $purple-tone-2;
        outline: none;
      }
    }

    .checkbox-container {
      display: inline-block;
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 16px;
      margin-top: 1rem;
    }

    .checkbox-container input {
      /* Hide the default */
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-container label {
      cursor: pointer;
      padding-left: 1.2rem;
      padding-top: 5px;
      
      p {
        font-size: 0.9rem;
        font-weight: normal;
      }
    }

    .checkbox-container label:before {
      content:"";
      position: absolute;
      height: 2.2rem;
      width: 2.2rem;
      top: 0;
      left: 0;
      background-color: $purple-tone-4;;
      border-radius: 10px;
    }

    .checkbox-container:hover input~label:before {
      background-color: $purple-tone-4;
    }

    .checkbox-container input:checked~label:before {
      background-color: $purple-tone-3;
    }

    .checkbox-container label:after{
      content: "";
      position: absolute;
      display: none;
      left: 14px;
      top: 8px;
      width: 5px;
      height: 12px;
      border: solid $neutral-600;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .checkbox-container input:checked~label:after {
      display: block;
    }
  }

  .action {
    .message {
      text-align: center;
      background: rgba($red-tone-3, 0.25);
      color: $red-tone-2;
      padding: 1rem 2rem;
      border-radius: 500px;
      margin-bottom: 2rem;
    }
  }

  button {
    width: 100%;
    background: $purple-tone-3;
    color: $neutral-600;
    border-radius: 500px;
    padding: 1rem;
    
    .darkmode & {
      color: $neutral-600;
    }
  }
</style>