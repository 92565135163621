<template>
  <div v-if="content" class="coaching-inner-wrapper">
    <div class="content">
      <span class="byline">{{ content.byline }}</span>
      <h2>{{ content.heading }}</h2>
      
      <span class="purple-loader"></span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {
        type: Object,
        default: null 
      },
    },

    methods: {
      callback(value) {
        this.$emit('callback', value);
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .coaching-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 4rem;
  }

  .byline {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1rem;
  }

  h2 {
    font-family: 'Brawler';
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 3rem;
  }
</style>