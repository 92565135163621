<template>
  <div v-if="content" class="coaching-inner-wrapper">
    <div class="content">
      <h2>{{ content.heading }}</h2>
      
      <div class="options">
        <div class="option" v-for="(option, idx) in content.options" :key="idx" :class="{'active' : selected === option}" @click="callback(option)">{{ option }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected: null,
      }
    },

    props: {
      content: {
        type: Object,
        default: null 
      },
    },

    methods: {
      callback(value) {
        this.selected = value;
        setTimeout(() => {
          this.$emit('callback', value);
        }, 900);
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .coaching-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
  }

  h2 {
    font-family: 'Brawler';
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 3rem;
  }

  .options {
    display: flex;
    flex-wrap: wrap;

    .option {
      display: flex;
      padding: 0.75rem 1.5rem;
      margin: 0 0.75rem 0.75rem 0;
      border-radius: 500px;
      border: 1px solid $purple-tone-3;
      color: $purple-tone-2;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        background: $purple-tone-3;
        color: $neutral-600;
      }

      &.active {
        background: $purple-tone-3;
        color: $neutral-600;
        animation: blink 0.5s linear 1;
      }

      @keyframes blink {
        0% {
          background-color: $purple-tone-3;
          transform: scale(1);
        }

        50% {
          background-color: $purple-tone-5;
          transform: scale(1.05);
        }
         
        100% {
          background-color: $purple-tone-3;
          transform: scale(1);
        }
      }
    }
  }
</style>