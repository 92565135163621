<template>
  <div>
    <transition name="slide">
      <div v-show="visible" class="onboarding-wrapper" :class="[`mq-${$mq}`]">
        <div class="inner" :class="[`mq-${$mq}`]">  
          <div class="header">
            <div class="back" @click="slideBack()">
              <div v-if="canGoBack" class="inner-btn">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3335 6.00011H14.6668M1.3335 6.00011L6.63683 11.3034M1.3335 6.00011L6.63683 0.696777" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <a @click="goToSlide('auth:sign_in')">Sign in</a>
          </div>

          <swiper-container
            :slidesPerView="1"
            :navigation="false"
            :allowSlideNext="false"
            :allowTouchMove="false"
            class="slide-swiper"
            id="swiper-child"
            >
            <swiper-slide v-for="(slide, idx) in flows[flow]" :key="idx" class="slide swiper-no-swiping" :id="slide.code">
              <component :is="slide.type" :content="slide.content" @callback="callback(slide.code, $event)" />
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </transition>

    <div v-if="visibleBackground" @click="hideModal()" class="background"></div>
  </div>
</template>

<script>
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import Message from '@/components/_v5/modals/inner/CoachingMessage.vue';
  import Question from '@/components/_v5/modals/inner/CoachingQuestion.vue';
  import Working from '@/components/_v5/modals/inner/CoachingWorking.vue';
  import Auth from '@/components/_v5/modals/inner/CoachingAuth.vue';

  export default {
    data() {
      return {
        visible: false,
        visibleBackground: false,

        flow: 'onboarding',

        canGoBack: false,
        slideBackIdx: null,

        flows: {
          onboarding: [
            {
              type: 'message',
              code: 'intro',
              content: {
                heading: `A fresh take on a hot topic - the news`,
                statements: [
                  'No ads, no clickbait',
                  'Be more informed',
                  'Spend less time reading'
                ],
                paragraph: 'We want you to be more informed. Spend less time reading. Expand your understanding.',
                action: 'Next',
              }
            },
            {
              type: 'question',
              code: 'topic',
              content: {
                heading: `Firstly — what's your favourite topic to read about?`,
                options: [
                  'Tech', 'Politics', 'Sports', 'Business', 'Science', 'Entertainment'
                ]
              }
            },
            {
              type: 'working',
              code: 'personalising',
              content: {
                heading: `We're personalsing your news`,
                byline: `Just a minute`,
              }
            },
            {
              type: 'auth',
              state: 'sign_up',
              code: 'auth:sign_up',
              content: {
                state: 'sign_up',
                heading: `Sign up to Nourish`,
                paragraph: `Get a better news experience.`,
                action: 'Sign up'
              }
            },
            {
              type: 'message',
              code: 'auth:notifications',
              content: {
                heading: `News when you need it`,
                statements: [
                  'Be more informed',
                  'Only coverage important to you',
                  'Stress free reading'
                ],
                paragraph: 'We want you to be more informed. Spend less time reading. Expand your understanding.',
                action: 'Turn on notifications',
              }
            },
            {
              type: 'auth',
              code: 'auth:sign_in',
              content: {
                state: 'sign_in',
                heading: `Sign in to Nourish`,
                paragraph: `Let's get back to reading better news.`,
                action: 'Sign in'
              }
            },
          ]
        }
      }
    },

    components: {
      Message,
      Question,
      Working,
      Auth
    },

    mounted () {
      this.EventBus.$on('onboarding', flow => {
        this.flow = flow;
        this.showOnboarding();
      });
    },

    methods: {
      showOnboarding() {
        this.visible = true;
        this.visibleBackground = true;
        document.body.style.overflow = 'hidden';
      },

      hideOnboarding() {
        this.visible = false;
        this.visibleBackground = false;
        document.body.style.overflow = 'unset'; 
      },

      callback(code, response) {
        console.log(`Onboarding callback from ${code}: `, response)
        this.slideNext()
      },

      slideBack() {
        const swiperEl = document.querySelector('#swiper-child');

        if (this.slideBackIdx !== null && this.canGoBack) {
          swiperEl.swiper.slideTo(this.slideBackIdx);
          if (this.slideBackIdx === 0) { this.canGoBack = false; }
          this.slideBackIdx = null;
        } else {
          swiperEl.swiper.slidePrev();
          this.canGoBack = !swiperEl.swiper.isBeginning;
        }
      },

      slideNext() {
        const swiperEl = document.querySelector('#swiper-child');
        swiperEl.swiper.slideNext();
        this.canGoBack = !swiperEl.swiper.isBeginning;
      },

      goToSlide(code) {
        // Navigates to specific slide by ID
        const swiperEl = document.querySelector('#swiper-child');
        const slides = swiperEl.swiper.slides;
        const idx = Array.from(slides).findIndex(slide => slide.id === code);

        if (idx !== -1) {
          // Store the current slide index before navigating
          this.slideBackIdx = swiperEl.swiper.activeIndex;

          // Slide found by code
          swiperEl.swiper.slideTo(idx);
          this.canGoBack = !swiperEl.swiper.isBeginning;
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 9999999;
    background: rgba($purple-tone-4, 0.7);
  }

  .onboarding-wrapper {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    background: rgba($purple-tone-6, 1.0);
    color: $neutral-600;

    .darkmode & {
      color: $neutral-600;
    }

    &.mq-md, &.mq-lg {
      top: 0;
      bottom: 0;
      width: 25rem;
      height: 75vh;
      border-radius: 20px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 2rem 1rem 2rem;
    
      a {
        color: $purple-tone-2;
        cursor: pointer;

        &:hover {
          color: $neutral-600;
        }
      }

      .back {
        width: 2.75rem;
        height: 2.75rem;

        .inner-btn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $purple-tone-3;
          border-radius: 500px;
        }
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: scroll;
      height: 100%;
      -ms-overflow-style: none;  // IE 10+
      overflow: -moz-scrollbars-none;  // Firefox
      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }

      &.overflow-wrap {
        overflow: hidden;
      }
    }
  }

  .slide-swiper {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  .slide {
    overflow-y: scroll;
  }
</style>