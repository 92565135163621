<template>
  <div class="story-wrap" @click="didSelectStory(story.slug)" :class="[getTypeClass, getReadClass, getViewBindingClass, getLayoutView, isHeaderView, hideStoryBreak]">
    
    <div class="inner-story-wrap">
      <div class="wrap-image">
        <div v-show="readState.code" v-if="readState.code !== 'updated' && readState.code !== 'preview'" class="read-state" :class="`read-state-${readState.code}`">
          <global-svg v-if="story.card === 'large'" class="icon" :type="getReadStateIcon(readState.code)" :color="getReadStateColor(readState.code)" width="14px" height="14px"/>
          <global-svg v-else class="icon" :type="getReadStateIcon(readState.code)" :color="getReadStateColor(readState.code)" width="11px" height="11px"/>
          <div class="read-state-stack" v-if="story.card === 'large'">
            <span class="read-state-label">{{ readState.name }}, {{ ago(readState.date) }}</span>
            <span class="read-state-intro">You read this story {{ when(readState.date) }}</span>
          </div>
        </div>

        <img :src="story.image" :alt="story.name" :class="getReadClass"/>
      </div>

      <div class="content-wrapper">
        <div class="wrap-content">
          <p class="wrap-intro-meta" :class="[getTypeClass, getViewBindingClass, getReadClass, isHeaderView]">
            <ul class="locales inline" v-if="getLocales && getLocales.length">
              <li v-for="locale in getLocales" :key="locale.topic_code" class="locale" :title="getTopic(locale.topic_code).meta.description">
                <!-- {{ getFlag(locale.topic_code) }} -->
                <img :src="`https://nourished.news/r/i/icons/country_flags/svg/${locale.topic_code}.svg`" class="flag"/>
                <!-- <div class="hovercard">
                  <h5>{{ getTopic(locale.topic_code).meta.name }}</h5>
                  <p>{{ getTopic(locale.topic_code).meta.byline }} &mdash; {{ getTopic(locale.topic_code).meta.description }}</p>
                </div> -->
              </li>
            </ul>
            <!-- <span>{{ story.score_recent | round }} / {{ story.cx_pub }}</span> -->
            <!-- [{{ readState.code }} {{ readState.unread }}] -->
            <span v-if="readState.code == 'updated'" class="tag-updated">Updated</span>
            <span v-if="story.card === 'tiny'" class="short-name-headline">{{ story.name }}</span>
            <span v-else class="short-name">{{ getShortName }}</span>
          </p>
          <h2 :class="[getTypeClass, getViewBindingClass, getReadClass, isHeaderView]">
            {{ story.name }}
          </h2>
        </div>

        <div class="wrap-meta">
          <!-- <div class="meta-last-update">
            <span :class="{'has-read' : hasRead(readState.code), 'read-state-read' : readState.code === 'read'}">{{ story.last_published | ago(isRoot) }}</span>
          </div> -->

          <!-- <div class="meta-read-state" :class="['read-state-' + (readState.code || 'new')]">
            <global-svg v-if="readState.code && (readState.code != 'updated')" class="icon" :type="getReadStateIcon(readState.code)" :color="getReadStateColor(readState.code)" height="12px"/>
            <div v-else class="read-state-unread">{{ readState.unread || Math.ceil(story.days) || '?' }}</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- <p>{{ story.synopsis }}</p> -->
    <div class="content-body" v-html="story.event ? story.event : story.synopsis"></div>

    <!-- <div v-if="isRoot && type === 'large'" class="story-topics">
      <div class="inner-topic" v-for="(topic, idx) in getTopics" :key="idx">
        <div class="topic-image" v-if="topic.topic_image">
          <img :src="topic.topic_image" :alt="topic.topic_name"/>
        </div>
        <h4>{{ topic.topic_name }}</h4>
      </div>
    </div> -->
  </div>

</template>

<script>
  const {flag} = require('country-emoji');
  import utils from '@assets/js/utils.js';
  import layout from '@assets/js/layout.js';
  import story from '@assets/js/news/story-utils.js';

  export default {
    mixins: [utils, layout, story],

    props: ['story', 'type', 'layout', 'isCard', 'isHeader', 'hideBreak', 'sectionName'],
    // Available type props: 'primary', 'secondary', 'slide', 'stack'

    mounted () {
      // console.log(`Story: (${this.type})`, 'isHeader: ', this.isHeader, this.story);
    },

    computed: {
      hasMobile() {
        return this.$store.getters.hasMobile;
      },

      isRoot() {
        if (this.levelIsRoot) { return true; }
        return false;
      },

      getLayoutView() {
        if (this.story.card) {
          return `layout-view-${this.story.card}`
        }
        return '';
      },

      isHeaderView() {
        if (this.isHeader) { return 'view-header' }
        return '';
      },

      hideStoryBreak() {
        if (this.hideBreak) { return 'hide-break'}
        return '';
      },

      getTypeClass() {
        return '';
        //return `story-type-${this.type}`;
      },
      
      readState() {
        return this.$store.getters.readState(this.story.slug) || {};
      },

      getReadClass() {
        if (this.hasRead(this.readState.code)) {
          return 'has-read';
        }
        return '';
      },

      getTopics() {
        return this.story.topics?.slice(0, 6).map(a => this.$store.getters.getLibraryItem('topics_v3', a.topic_code)).filter(a => a?.meta?.image).slice(0, 3);
      },

      getLocales() {
        if (this.story?.localeContext) {
          // console.log("LocaleContext: ", this.story.localeContext, this.story.locale);
          return this.story?.locale?.filter(a => a.topic_code != this.story?.localeContext?.code);
        }
        return this.story?.locale;
      },

      getImageTopics() {
        const topics = this.story.topics?.slice(0, 3);
        let images = []
        topics?.forEach(topicStem => {
          let topic = this.$store.getters.getLibraryItem('topics_v3', topicStem.topic_code);
          
          if (!topic?.meta?.image) {
            return;
          }

          images.push(topic.meta.image);
        });

        return images;
      },

      getShortName() {
        if (this.story?.category) {
          return this.story?.category;
        }
        
        // grap all our topics
        let aTopics = this.story?.topics || [];

        // if we're in a section about a topic, don't repeat/show that topic (its repetitive)
        if (this.story.topicContext) {
          let aTopicContext = this.story.topicContext.split(':');
          aTopics = this.story?.topics?.filter(a => !aTopicContext.includes(a.topic_code)) || [];
        }

        // now grab the names & return
        aTopics = aTopics?.map(a => a.topic_name)?.filter(String)?.slice(0,3);

        // hacky fallback
        if (!aTopics.length) {
          aTopics = this.story?.topics?.map(a => a.topic_name)?.filter(String)?.slice(0,3);
        }

        return aTopics?.join(', ');
      },

      showNewUpdateTag() {
        return false;
      },
    },

    methods: {
      getFlag(code) {
        return flag(code);
      },

      didSelectStory(slug) {
        if (!this.isHeader) {
          // this.$router.push(`/v3/story/${slug}`)

          this.$router.push(`/story/${slug}`)
          // this.EventBus.$emit("modal:full-story", slug);
        }
      },

      isType(type) {
        if (this.type === type) { return true; }
        return false;
      },

      hasRead(code) {
        if (code && (code != 'updated')) { return true; }
        return false;
      },

      getReadStateColor(code) {
        switch (code) {
          case 'preview': return '#6D7479'
          case 'glanced': return '#6D7479'
          case 'skimmed': return '#6D7479'
          case 'read': return '#14B31F'
        }
      },

      getReadStateIcon(code) {
        if (code) {
          if (code === 'skimmed' || code === 'read') {
            return 'double-tick'
          }

          return 'single-tick'
        }
      },
      
      getTopic(code) {
        return this.$store.getters.getLibraryItem('topics_v3', code) || {meta: {}};
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .story-wrap {
    @include palette-block;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem;
    
    cursor: pointer;

    .mobile & {
      background: unset;
    }

    &.view-header {
      padding: 0;
    }
    
    .mobile & {
      &:not(.view-header):not(.story-type-progress) {
        // border-bottom: 1px solid $neutral-450;
        @include palette-border-bottom;
      }
    }
  }

  .inner-story-wrap {
    display: flex;
    width: 100%;
  }

  .tag-updated {
    display: inline-block;
    background: $yellow-tone-3;
    font-family: $ff-tag;
    font-size: 6px;
    line-height: 1em;
    padding: 3px 4px 3px 4px;
    border-radius: 3px;
    position: relative;
    top: -1px;
    margin-right: 2px;
  }

  .wrap-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $el-border-radius;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;

      &.has-read {
        -webkit-filter: brightness(30%);
        filter: brightness(30%);
      }
    }
  }

  .content-wrapper {
    display: flex;
    align-items: flex-start;
    flex: 1;
  }

  .content-body {
    display: none;
  }

  .wrap-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 0.8em;

    h2 {
      @include typo-size-small-heading;
      @include limit-2-lines;
      margin-top: 0;
      margin-bottom: 0.3em;
    }

    p {
      @include typo-size-body-small;
      @include limit-1-line;
      margin: 0;

      .mobile & {
        @include typo-size-body-small;
      }
    }
  }

  .wrap-meta {
    display: flex;
    flex-direction: column;

    .meta-last-update {
      display: flex;
      justify-content: flex-end;
      @include typo-size-small;
      color: $orange-tone-3;

      .has-read {
        color: $neutral-400;
      }

      .read-state-read {
        color: $green-tone-3;
      }
    }

    .meta-read-state {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.4em;

      .read-state-unread {
        font-size: 8px;
        font-family: $ff-tag;
        text-indent: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $orange-tone-3;
        color: $white-primary;
      }
      &.read-state-updated {
        .read-state-unread {
          background: $orange-tone-3;
          // color: #000;
        }
      }
    }
  }

  .story-topics {
    display: flex;
    padding: 0.8em;

    .inner-topic {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 1em;
      }
      
      .topic-image {
        display: flex;
        width: 10px;
        height: 10px;
        margin-right: 0.4em;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;

          // hide alt-text on missing images
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      h4 {
        @include typo-size-small;
        margin: 0;
      }
    }
  }


  // ********************************************
  // Destop Grid Rules
  // ********************************************

  .desktop {
    .story-grid {
      .story-wrap.view-bind-root {
        border-radius: $el-border-radius;

        &.layout-view-large {
          grid-column: span 2;
          grid-row: span 10;
        }

        &.layout-view-medium {
          grid-column: span 2;
          grid-row: span 4;
        }

        &.layout-view-small {
          grid-column: span 2;
        }

        &.layout-view-tiny {
          grid-column: span 1;
        }

      }
    }
  }


  // ********************************************
  // Large Story Card
  // ********************************************
  .layout-view-large {   
    &.story-wrap {
      display: flex;
      flex-direction: column;
      padding: unset;
    }

    .inner-story-wrap {
      flex-direction: column-reverse;

      .mobile & {
        flex-direction: column;
      }
    }

    .wrap-image {
      width: 100%;
      height: 250px;

      .mobile & {
        height: 150px;
      }
      
      img {
        border-radius: 0;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .content-wrapper {
      width: 100%;
      padding: 0.8em;

      .wrap-content {
        margin-left: 0;

        h2 {
          @include typo-size-headline;
        }
      }
    }

    .content-body {
      // display: flex;
      display: block;
      padding: 0.8em;

      .mobile & {
        padding: 0 0.8em 1em 0.8em;
      }

      p {
        @include typo-size-body;
        @include limit-3-lines;
        margin: 0;
      }
    }
  }



  // ********************************************
  // Medium Story Card
  // ********************************************
  .layout-view-medium {
    padding: 1rem;

    .inner-story-wrap {
      display: flex;
      flex-direction: row-reverse;
    }

    .wrap-image {
      width: 75px;
      height: 75px;
    }

    .content-wrapper {
      width: 100%;
      margin-right: 1em;

      .wrap-content {
        margin-left: 0;

        h2 {
          @include typo-size-heading;

          .mobile & {
            @include typo-size-headline;
          }
        }
      }
    }

    .content-body {
      // display: flex;
      display: block;
      margin-top: 0.5em;

      p {
        @include typo-size-body;
        @include limit-3-lines;
        margin: 0;
      }
    }
  }


  // ********************************************
  // Tiny Story Card
  // ********************************************
  .layout-view-tiny {
    order: 99;

    &.story-wrap {
      padding: 0.6em 0.8em 0.5em 0.8em;
      background: rgba($green-tone-3, 0.05);
      border-left: 4px solid $green-tone-3;
      border-bottom: 0px solid $green-tone-3;
    }

    .inner-story-wrap {
      display: flex;
      flex-direction: row-reverse;
    }

    .wrap-image {
      display: none;
    }

    .wrap-meta {
      flex-direction: row;

      .meta-read-state {
        margin-top: 0;
        margin-left: 0.4em;
      }
    }

    .content-wrapper {
      width: 100%;

      .wrap-content {
        margin-left: 0;

        .short-name-headline {
          @include limit-1-line;
          display: inline;
          font-weight: bold;
        }

        h2 {
          display: none;
        }
      }
    }

    .content-body {
      display: none;
    }
  }

  .hide-break {
    border-bottom: unset !important;
  }

  .locales.inline {
    position: relative;
    top: 2px; 
    display: inline-block;
    margin-right: .5em;

    .locale {
      display: inline-block;
      
      &:not(:first-child) {
        margin-left: .5em;
      }

      .flag {
        width: 18px;
        height: 10px;
        object-fit: cover;
        border-radius: 2px;
      }

    }
  }

  // ********************************************
  // Read states
  // ********************************************
  .has-read {
    &.view-bind-root {
      h2, p {
        opacity: 0.8;
      }
    }
  }

  .read-state {
    position: absolute;
    display: flex;
    padding: 0.5em;
    z-index: 1;
    backdrop-filter: blur(10px);

    .read-state-stack {
      @include typo-size-small;
      display: flex;
      flex-direction: column;
      
      margin-left: .5em;

      .read-state-intro {
        color: $white-primary;
      }
    }
  }

  .read-state-preview {
    color: $neutral-400;
  }

  .read-state-glanced span {
    color: $red-tone-3;
  }

  .read-state-skimmed {
    color: $orange-tone-3;
  }

  .read-state-read {
    color: $green-tone-3;
  }


  // ********************************************
  // View Bind Page: Overrides
  // ********************************************
  .view-bind-page:not(.view-header) {    
    &:not(.layout-view-tiny) {
      background: none;
      padding: 0;
    }

    .wrap-image {
      display: none;
    }

    .wrap-content {
      margin: 0 0.5em 0 0;

      h2 {
        @include typo-size-small-heading;
      }
    }

    .wrap-meta {
      .meta-last-update {
        display: none;
      }
    }

    .content-wrapper {
      align-items: center;
      padding: 0;
      margin-right: 0;
    }

    .content-body {
      display: none;
    }
  }



  // ********************************************
  // View Header: Overrides
  // ********************************************
  .view-header {
    background: none;
    width: 100%;

    .inner-story-wrap {
      width: 100%;
      flex-direction: row-reverse;
    }

    .wrap-image {
      width: 60px;
      height: 60px;
    }

    .wrap-content {
      margin-left: 0;

      h2 {
        @include typo-size-heading;
      }
    }

    .content-wrapper {
      align-items: center;
      margin-right: 1em;
    }
  }













  // .story-wrap {
  //   &.view-bind-root {
  //     padding: 1.3em 1em;
  //     // background: rgba($yellow-tone-7, 0.02);

  //     &.story-type-primary {
  //       grid-column: span 2;
  //       grid-row: span 3;
  //     }

  //     &.story-type-secondary {
  //       grid-column: span 2;
  //     }

  //     &.story-type-slide {
  //       padding: 1.3em 1em;
  //       font-size: 12px;
  //       // background: $neutral-600;
  //     }

  //     .mobile & {
  //       border-bottom: 1px solid $neutral-450;
  //     }
  //   }

  //   &.view-bind-page,
  //   &.view-bind-detail {
  //     &.story-type-slide {
  //       padding: 0;
  //       background: unset;
  //     }
  //   }

  //   &.view-header {
  //     width: 100%;

  //     h2 {
  //       @include limit-1-line;
  //     }

  //     .mobile & {
  //       .story-content {
  //         p {
  //           @include limit-1-line;
  //         }

  //         .story-topics {
  //           display: none;
  //         }
  //       }
  //     }
  //   }

  //   .story-head-stack {
  //     display: flex;
  //     flex-direction: column;

  //     &.story-type-secondary,
  //     &.story-type-slide {
  //       flex-direction: row;
  //     }

  //     .story-lead {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       flex: 1;

  //       &.view-bind-root {
  //         margin-bottom: 0.6em;
  //       }

  //       h2 {
  //         @include typo-size-headline;
  //         margin-right: 0.75em;

  //         &.story-type-secondary {
  //           @include typo-size-secondary-headline;
  //         }

  //         &.view-bind-root {
  //           &.story-type-slide,
  //           &.story-type-stack {
  //             @include typo-size-small-heading;
  //             @include limit-2-lines;
  //           }
  //         }


  //         &.view-bind-detail,
  //         &.view-bind-page {
  //           &:not(.view-header) {
  //             @include typo-size-body;
  //             padding: 0 1em 0 0;
  //             border-right: 2px solid transparent;
  //           }
  //         }
  //       }

  //       p {
  //         @include typo-size-body;
  //         margin: 0;
  //       }

  //       &.current {
  //         &.view-bind-detail,
  //         &.view-bind-page {
  //           border-right: 2px solid $yellow-tone-3;

  //           h2 {
  //             @include typo-size-small-heading;
  //           }
  //         }
  //       }

  //       .story-meta {
  //         display: flex;
  //         align-items: center;
  //         margin: 0.4em 0;
  //       }
  //     }

  //     .inner-meta {
  //       display: flex;
  //       align-items: center;
        
  //       &:not(:last-child) {
  //         margin-right: 0.6em;
  //       }

  //       .icon {
  //         margin-right: 0.3em;
  //         &:not(:first-of-type) {
  //           margin-left: 0.3em * 2;
  //         }
  //       }

  //       span {
  //         @include typo-size-small;
          
  //         &:not(.read-state-label):not(.read-state-intro):not(.meta.read-state) {
  //           color: $yellow-tone-5;
  //         }
  //       }

  //       &.meta-tag {
  //         background: $yellow-tone-3;
  //         padding: 3px 4px 2px 4px;
  //       }

  //       &.state-preview {
  //         color: $neutral-400;
  //       }

  //       &.state-glanced {
  //         color: $red-tone-3;
  //       }

  //       &.state-skimmed {
  //         color: $orange-tone-3;
  //       }

  //       &.state-read {
  //         color: $green-tone-3;
  //       }
  //     }

  //     .story-image {
  //       position: relative;
  //       width: 100%;
  //       height: 250px;
  //       margin: 0.2em 0 1.1em 0;

  //       display: flex;
  //       align-items: center;
  //       justify-content: center;

  //       &.story-type-secondary,
  //       &.story-type-slide {
  //         width: 75px;
  //         height: 50px;
  //       }

  //       &.story-type-stack {
  //         width: 100%;
  //         height: 92px;
  //       }

  //       &.view-header {
  //         width: 100px;
  //         height: 75px;

  //         .mobile & {
  //           width: 75px;
  //           height: 50px;
  //         }
  //       }
        
  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //         border-radius: $el-border-radius;

  //         // hide alt-text on missing images
  //         text-indent: 100%;
  //         white-space: nowrap;
  //         overflow: hidden;

  //         &.has-read {
  //           -webkit-filter: brightness(30%);
  //           filter: brightness(30%);
  //         }
  //       }

  //       .story-image-wrap {
  //         height: 100%;
  //       }

  //       .story-topics {
  //         display: flex;
  //         align-items: center;
  //         position: absolute;
  //         right: 0;
  //         margin-top: -8px;
  //         margin-right: -8px;
  //         z-index: 5;

  //         .story-topic-image {
  //           display: flex;
  //           width: 25px;
  //           height: 25px;

  //           &:not(:first-child) {
  //             margin-left: -10px;
  //           }

  //           img {
  //             width: 100%;
  //             height: 100%;
  //             object-fit: cover;
  //             border-radius: 50%;
  //             border: 2px solid $white-primary;
              
  //             // hide alt-text on missing images
  //             text-indent: 100%;
  //             white-space: nowrap;
  //             overflow: hidden;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .story-content {
  //     p {
  //       @include typo-size-body;
  //       margin: 0;
  //     }

  //     &.story-type-secondary,
  //     &.story-type-stack {
  //       p {
  //         @include limit-3-lines;
  //       }
  //     }

  //     &.story-type-slide {
  //       p {
  //         @include limit-2-lines;
  //       }
  //     }

  //     .story-topics {
  //       display: flex;
  //       margin-top: 1.1em;

  //       .inner-topic {
  //         display: flex;
  //         align-items: center;

  //         &:not(:last-child) {
  //           margin-right: 1em;
  //         }
          
  //         .topic-image {
  //           display: flex;
  //           width: 10px;
  //           height: 10px;
  //           margin-right: 0.4em;

  //           img {
  //             width: 100%;
  //             height: 100%;
  //             object-fit: cover;
  //             border-radius: 50%;

  //             // hide alt-text on missing images
  //             text-indent: 100%;
  //             white-space: nowrap;
  //             overflow: hidden;
  //           }
  //         }

  //         h4 {
  //           @include typo-size-small;
  //           margin: 0;
  //         }
  //       }
  //     }
  //   }
  // }


  
</style>