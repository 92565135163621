<template>
  <li class="plans default" v-if="getMode == 'default'">
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="plan" :success-url="successUrl" :cancel-url="cancelUrl" :customer-email="customerEmail"/>
    <button class="go-pro" @click="checkout">Go Pro Now</button>
  </li>
  <div v-else-if="getMode == 'message-card'" class="product">
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="plan" :successUrl="successUrl" :cancelUrl="cancelUrl" :customerEmail="customerEmail" />
    <button class="full" :class="isGoldilocks ? 'primary' : ''" @click="checkout">
      <div class="product-intro">
        <div class="product-stack">
          <span class="product-heading">{{ priceName }}</span>
          <span class="product-description">{{ priceDescription }}</span>
        </div>
      </div>
      <div class="product-price">
        <span class="locale-price">{{ priceString }}</span>
      </div>
    </button>
  </div>
</template>


<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  components: {
    StripeCheckout
  },
  props: ['period', 'goldilocks', 'mode'],
  data: () => ({
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    // successUrl: document.location.origin + document.location.pathname + '#pro_thanks',
    // cancelUrl: document.location.origin + document.location.pathname,
    successUrl: 'https://nourished.news/' + '#pro_thanks',
    cancelUrl: 'https://nourished.news/' + '#pro_nopro',
    plans: {
      W : {
        price: 2.99,
        period: 'per week',
        name: 'Weekly',
        description: 'Support OneSub each week',
      },
      M : {
        price: 8.99,
        period: 'per month',
        name: 'Monthly',
        description: 'Support OneSub monthly',
      },
      Y : {
        price: 99.99,
        period: 'per year',
        name: 'Yearly',
        description: 'Support OneSub for a year',
      },
    },
  }),
  computed: {
    getMode() {
      return this.mode || 'default';
    },
    testMode() {
      // throw #test onto the URL & reload to hit Stripe Test Sandbox
      // return this.$store.getters.isAdmin && document.location.hash.match(/test/);
      return false;
    },
    customerEmail () {
      return this.$store.getters.getAccount.details.email;
    },
    customerCurrency () {
      return this.localisedPlans;
    },
    localisedPlans() {
      let country = this.$store.getters.getAccount.preferences.country;
      let continent = this.$store.getters.getAccount.preferences.continent;
      if (country == 'GB'){
        return {
          code: 'GBP',
          symbol: '£',
          plans: {
            // 'W': 'price_1IREuNAuyve4KnvCsEEw5dG0',
            // 'M': 'plan_HV9XYPVMv0yuwa',
            // 'Y': 'plan_HV9XsuMQQqkFzX',
            'M': 'price_1PDkMRAuyve4KnvCiAJgCeAt', // 2024: £5/m (single product, multiple currencies :crosses-fingers:)
          },
        }
      } else if (continent == 'EU') {
        return {
          code: 'EUR',
          symbol: '€',
          plans: {
            // 'W': 'price_1IREv4Auyve4KnvCRd5uxUJO',
            // 'M': 'price_1Gw9XQAuyve4KnvCW7q5rW29',
            // 'Y': 'price_1Gw9WtAuyve4KnvCbtrgc7ZP',
            'M': 'price_1PDkMRAuyve4KnvCiAJgCeAt', // 2024: €6/m (single product, multiple currencies :crosses-fingers:)
          },
        }
      } else {
        return {
          code: 'USD',
          symbol: '$',
          plans: {
            // 'W': 'price_1IREumAuyve4KnvCJfpODDoF',
            // 'M': 'price_1Gw9YMAuyve4KnvCmUBs0sYy',
            // 'Y': 'price_1Gw9Y0Auyve4KnvCfVco4fxF',
            'M': 'price_1PDkMRAuyve4KnvCiAJgCeAt', // 2024: $5/m (single product, multiple currencies :crosses-fingers:)
          },
        }
      }
    },
    plan () {
      const plan = [
        {
          // "plan": "OSSUBV1" + this.period + "_" + this.customerCurrency.code,
          "price": this.testMode ? "OSSUBV1" + this.period + "_" + this.customerCurrency.code : this.localisedPlans.plans[this.period],
          "quantity": 1
        },
      ]
      return plan
    },
    priceString () {
      return this.customerCurrency.symbol + this.plans[this.period].price;
    },
    periodString () {
      return this.plans[this.period].period;
    },
    priceName () {
      return this.plans[this.period].name;
    },
    priceDescription () {
      return this.plans[this.period].description;
    },
    isGoldilocks () {
      return this.period == 'M';
    }
  },
  methods: {
    checkout () {
      if (this.$refs.checkoutRef) {
        this.$refs.checkoutRef.redirectToCheckout();
      }
    }
  }
}
</script> 

<style lang="scss" scoped>
  @import '~styles/weekly.scss';
  @import 'src/assets/scss/v5.scss';

  li {
    list-style: none;
  }

  .default.plans {
    
  }

  button {
    position: absolute;
    left: -1.5rem;
    right: -1.5rem;
    bottom: 2rem;
    height: 3rem;
    // background: $green-tone-3;
    // border: none;
    // padding: 15px 20px;
    // color: $green-tone-1;
    // border-radius: $border-radius;
    // font-size: 0.8em;
    // cursor: pointer;

    // @media(max-width: 475px) {
    //   width: 100%;
    //   padding: 13px 15px 10px 15px;
    // }

    &.primary {
      background: $green-tone-3;
      padding: 20px 20px;
      color: white;

      @media(max-width: 475px) {
        width: 100%;
        padding: 13px 15px 10px 15px;
      }
    }
  }

  .inner {
    @media(max-width: 475px) {
      width: 100%;
    }
  }

  .product-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .product-heading {
      font-family: $ff-tag;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .product-description {
      font-family: $ff-body;
      font-size: 13px;
    }

  }

  .product-price {
    font-family: $ff-tag;
    font-size: 16px;
  }

  .full {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .product-heading {
      text-align: left;
    }

    .product-description {
      text-align: left;
      margin-bottom: 5px;
    }
  }

  // .product {

  //   button {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     justify-content: space-between;
  //     background: $green-tone-4;
  //     color: $green-tone-1;
  //     padding: 12px 15px;
  //     border-radius: $border-radius;
  //     border: 0;
  //     width: 100%;
  //     cursor: pointer;

  //     &:not(:last-child) {
  //       margin-bottom: 10px;
  //     }

  //     &.primary {
  //       background: $green-tone-3;
  //       color: white;
  //       font-size: 1em;
  //       font-weight: bold;
  //     }

  //     .product-intro {
  //       display: flex;
  //       flex-direction: column;
  //       text-align: left;

  //       .product-heading {
  //         font-family: $ff-bold;
  //         font-size: 15px;
  //       }

  //       .product-description {
  //         font-family: $ff-book;
  //         font-size: 13px;
  //       }
  //     }

  //     .product-price {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;

  //       .locale-price {
  //         font-size: 14px;
  //         font-weight: bold;
  //       }
  //     }
  //   }
  // }

</style>