<template>
  <div class="playlist-coach">
    <div class="brand">
      <svg class="nourish-logo" width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8155 0.333496H25.2108L26 27.543L22.3455 28.528C19.0387 21.6494 15.9202 17.0187 12.0139 12.5849C14.2398 17.1322 16.1344 21.5113 17.9382 26.9294L14.7827 29.0821C12.9082 27.1982 11.3929 25.6079 10.0191 24.1662C9.32427 23.437 8.66566 22.7459 8.01512 22.0739C8.35032 23.228 8.64076 24.4982 8.89176 25.9526L9.11995 27.2747L6.17839 29.3335L1.08112 27.7603L2.16583 23.8458L4.67471 24.6202C3.75914 20.7111 2.39269 18.4625 0 14.55L2.80964 11.8344C6.16062 14.5846 8.47279 16.8392 10.8231 19.2666C8.89802 14.89 6.71962 10.7221 4.00901 5.5359C3.5973 4.74818 3.17331 3.93697 2.73606 3.09797L5.71753 0.595527C12.5625 7.05909 17.298 11.8688 21.7241 19.129L20.8155 0.333496Z" fill="black"/>
      </svg>
    </div>

    <div v-if="item.modal === 'balanced-diet'" class="content">
      <h2>Balanced diet</h2>
      <h3>News that's good for you</h3>
      <p>At Nourish, we believe news should inform, inspire, and empower—not exhaust you. Just like a well-balanced meal fuels your body, a well-balanced news diet fuels your mind. That's why we focus on delivering journalism that is insightful, diverse, and enriching, rather than overwhelming or anxiety-inducing.</p>
      <p>A Balanced Diet means getting the full picture—fact-based reporting, meaningful stories, and a variety of perspectives that help you understand the world without feeling weighed down. We curate content that provides depth and nuance, rather than just headlines designed to grab your attention. Instead of endless breaking news and crisis-driven coverage, we highlight stories that encourage understanding, solutions, and progress.</p>
      <p>We know it's easy to get caught up in the 24/7 news cycle, but overconsumption of negativity can take a toll. Our goal is to help you engage with the world in a healthier way—by offering news that's good for you, not just designed to keep you scrolling. Stay informed, stay mindful, and nourish your curiosity with a news experience that values quality over noise.</p>
    </div>

    <div v-else-if="item.modal === 'digital-sugar'" class="content">
      <h2>No digital sugar</h2>
      <h3>Better for mental health</h3>
      <p>Not all news is created equal. The modern news landscape is flooded with digital sugar—clickbait, outrage-driven headlines, and doomscrolling traps that leave you feeling drained rather than informed. Just like too much processed sugar can impact your physical health, excessive exposure to sensationalized news can take a toll on your mental well-being.</p>
      <p>At Nourish, we're cutting out the unhealthy additives in your news diet. Our AI helps filter out excessive negativity and emotionally manipulative content, prioritizing journalism that offers context, clarity, and balance. This doesn't mean we ignore serious issues—it means we present them in a way that encourages understanding, rather than anxiety.</p>
      <p>You deserve to stay informed without feeling overwhelmed. That's why we focus on thoughtful, constructive reporting that gives you the information you need without the unnecessary stress. A healthier mind starts with a healthier news experience—one that empowers rather than drains. With Nourish, you can break free from the cycle of digital sugar and build a news diet that truly supports your well-being.</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: null
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .playlist-coach {
    margin-bottom: 1rem;
    padding-top: 2.5rem;
    max-height: 70vh;
    overflow: scroll;
    -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

    .brand {
      margin-bottom: 2rem;
    }

    .content {
      overflow: scroll;
      text-align: left;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      h2 {
        font-size: 1.8rem;
        font-family: 'Brawler';
      }

      h3 {
        font-size: 1rem;
        font-weight: normal;
        color: $neutral-400;
        margin: 0.5rem 0 2rem 0;
      }
    }
  }
</style>