<template>
  <div v-if="content" class="coaching-inner-wrapper">
    <div class="content">
      <h2>{{ content.heading }}</h2>
      <div class="statements">
        <div class="statement" v-for="(statement, idx) in content.statements" :key="idx">
          <p>{{ statement }}</p>
        </div>
      </div>
      <p>{{ content.paragraph }}</p>
    </div>


    <div class="action">
      <transition name="slide-up">
        <button v-if="showButton" @click="callback()">{{ content.action }}</button>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showButton: false
      }
    },

    props: {
      code: {
        type: String,
        default: null,
      },
      content: {
        type: Object,
        default: null 
      },
    },

    mounted () {
      setTimeout(() => {
        this.showButton = true;
      }, 3000);
    },

    methods: {
      callback() {
        this.$emit('callback', 'next');
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .coaching-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
  }

  h2 {
    font-family: 'Brawler';
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 3rem;
  }

  .statements {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    .statement {
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      p {
        &:before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 1px;
          background: $neutral-600;
          margin-right: 0.75rem;
          margin-bottom: 5px;
        }
      }
    }
  }

  p {
    font-size: 20px;
    font-family: 'Brawler';
  }

  button {
    width: 100%;
    background: $purple-tone-3;
    color: $neutral-600;
    border-radius: 500px;
    padding: 1rem;
    
    .darkmode & {
      color: $neutral-600;
    }
  }
</style>