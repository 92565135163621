<template>
  <div>
    <transition name="slide">
      <div v-show="visible" class="qr-wrapper" :class="[`mq-${$mq}`]">
        <div class="close" @click="hideModal()">
          <base-svg svg="close" height="0.85rem" fill="white"/>
        </div>

        <div class="qr-inner" :class="[`mq-${$mq}`]"> 
          <div class="brand">
            <svg class="nourish-logo" width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8155 0.333496H25.2108L26 27.543L22.3455 28.528C19.0387 21.6494 15.9202 17.0187 12.0139 12.5849C14.2398 17.1322 16.1344 21.5113 17.9382 26.9294L14.7827 29.0821C12.9082 27.1982 11.3929 25.6079 10.0191 24.1662C9.32427 23.437 8.66566 22.7459 8.01512 22.0739C8.35032 23.228 8.64076 24.4982 8.89176 25.9526L9.11995 27.2747L6.17839 29.3335L1.08112 27.7603L2.16583 23.8458L4.67471 24.6202C3.75914 20.7111 2.39269 18.4625 0 14.55L2.80964 11.8344C6.16062 14.5846 8.47279 16.8392 10.8231 19.2666C8.89802 14.89 6.71962 10.7221 4.00901 5.5359C3.5973 4.74818 3.17331 3.93697 2.73606 3.09797L5.71753 0.595527C12.5625 7.05909 17.298 11.8688 21.7241 19.129L20.8155 0.333496Z" fill="black"/>
            </svg>
          </div>  
          <h1>Get the Nourish app</h1>
          <p>For the best possible news experience, scan the QR code and download the app. Available on iOS and Android.</p>

          <div class="qr-container">
            <img class="qr-code" src="@/assets/images/nourish-qr-app-launch.png"/>
          </div>

          <div class="button-container">
            <div @click="fireCta('apple')" class="button-element">
              <img class="button-svg" src="@/assets/images/badge-apple.svg"/>
            </div>
            <div @click="fireCta('android')" class="button-element">
              <img class="button-svg" src="@/assets/images/badge-google.svg"/>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="visibleBackground" @click="hideModal()" class="background"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        visibleBackground: false,
      }
    },

    mounted () {
      this.EventBus.$on('modal:qr', () => {
        // Hide mobile navigation if expanded
        this.EventBus.$emit('header:hide');
        this.visibleBackground = true;
        this.visible = true;

        // Track when GoPro modal is shown
        this.EventBus.$emit('track:event', {
          label: 'QR: Show',
          once: true, // default
        });
      });
    },

    methods: {
      hideModal() {
        this.visible = false;
        this.visibleBackground = false;
      },

      fireCta(platform) {
        if (platform === 'apple') {
          window.open('https://nourished.news/ios', '_blank').focus();
        } else if (platform === 'android') {
          window.open('https://nourished.news/android', '_blank').focus();
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/v5.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 9999999;
  background: rgba($yellow-tone-3, 0.75);
}

// Mobile
.qr-wrapper {
  position: fixed;
  margin: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;
  background: rgba($neutral-600, 1.0);
  color: $neutral-100;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  border-radius: 30px;

  .darkmode & {
    background: rgba($neutral-600, 1.0);
    color: $neutral-100;
  }

  &.mq-md, &.mq-lg {
    display: flex;
    align-self: center;
    margin: auto;
    max-width: 28rem;
    height: auto;
    border-radius: $border-radius;
  }

  .close {
    position: absolute;
    top: env(safe-area-inset-top);
    right: 0;
    padding: 1.5rem;
    z-index: 99;
    cursor: pointer;
    background: transparent;
  }

  .qr-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3.2rem 1.5rem;
    overflow-y: scroll;
    height: 100%;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    &.mq-md, &.mq-lg {
      padding: 4rem 2rem 4rem 2rem;
    }

    &.overflow-wrap {
      overflow: hidden;
    }

    .brand {
      margin-bottom: 2rem;

      svg path {
        fill: $neutral-100;
      }
    }

    h1 {
      margin-bottom: 0.75rem;
      text-align: center;
      font-size: 1.5rem;
    }

    p {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 16px;
    }

    .qr-container {
      max-width: 60%;
      margin: auto;

      .qr-code {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .button-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      margin: auto;
      padding: 1rem;
      margin-top: 2rem;
      width: 100%;

      .button-element {
        cursor: pointer;

        .button-svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>